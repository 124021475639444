.form {
  border-radius: 15px;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.form__inputs {
  box-sizing: border-box;
  background-color: var(--primary);
  padding: 17px 30px 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: 'from to date-to date-back';
  gap: 25px;
}

@media (max-width: 768px) {
  .form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'from to'
      'date-to date-back';
  }
}

@media (max-width: 425px) {
  .form__inputs {
    grid-template-columns: 1fr;
    grid-template-areas:
      'from'
      'to'
      'date-to'
      'date-back';
  }
}

.form_loading .form__inputs {
  background-image: url(../../images/icon__plane.svg);
  background-size: 1.5em 1.5em;
  animation: flying-planes 1.5s infinite linear,
    changing-colors 1s infinite linear alternate;
}

.form__input-container {
  display: flex;
  flex-direction: column;
}

.form__input-container_type_from {
  grid-area: from;
}

.form__input-container_type_to {
  grid-area: to;
}

.form__input-container_type_date-to {
  grid-area: date-to;
  z-index: 1;
}

.form__input-container_type_date-back {
  grid-area: date-back;
}

.form__input-label {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.18;
  color: var(--on-primary);
}

.form__input {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 10px;
  border: none;
  color: var(--input-text);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
}

.form__input::placeholder {
  color: var(--placeholder);
}

.form__input:focus {
  outline: 1px solid var(--on-primary-variant);
  outline-offset: 1px;
}

.form__input-container_type_date-back .form__input::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: -40px;
  height: 0;
  width: 40px;
  border-bottom: 1px dashed var(--on-primary);
  transform: translateY(-50%);
}

@media (max-width: 425px) {
  .form__input-container_type_date-back .form__input::before {
    top: 0;
    left: 50%;
    transform: translateY(-50%) rotate(-90deg);
    transform-origin: 0 0;
  }
}

.form__input_type_date {
  padding-left: 36px;
}

.form__input_type_date::-webkit-calendar-picker-indicator {
  background-image: url(../../images/icon__calendar.svg);
  position: absolute;
  left: 5%;
}

.form__input_type_date:focus::-webkit-calendar-picker-indicator {
  background-image: url(../../images/icon__calendar_active.svg);
}

.form__submit-button {
  align-self: flex-end;
  margin: 23px 30px;
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
  background-color: var(--primary);
  color: var(--on-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  transition: transform 0.1s, background-color 0.2s, color 0.2s;
}

.form__submit-button:hover {
  background-color: var(--primary-variant);
}

.form__submit-button:active {
  transform: scale(0.95);
}

.form__submit-button:disabled {
  background-color: var(--inactive);
  pointer-events: none;
}

.form_loading .form__submit-button {
  color: var(--on-primary-variant);
  pointer-events: none;
}

@media (max-width: 425px) {
  .form__submit-button {
    align-self: center;
  }
}

@keyframes flying-planes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1.5em -1.5em;
  }
}

@keyframes changing-colors {
  from {
    background-color: var(--primary);
  }
  to {
    background-color: #675cdb;
  }
}
