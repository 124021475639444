@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./Roboto-Black.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('./Roboto-BlackItalic.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('./Roboto-BoldItalic.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('./Roboto-Italic.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('./Roboto-LightItalic.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('./Roboto-MediumItalic.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('./Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('./Roboto-ThinItalic.ttf');
}
