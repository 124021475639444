.footer {
  padding-block: 2em;
}

.footer__text {
  margin: 0;
  text-align: center;
  font-size: smaller;
  opacity: 0.7;
}
