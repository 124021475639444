.tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: baseline;
}

.tabs__radio-container {
  transition: opacity 0.2s;
  cursor: pointer;
}

.tabs__radio-container:hover {
  opacity: 0.8;
}

.tabs__radio-input {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.tabs__radio-label {
  position: relative;
  display: block;
  padding: 6px;
  color: var(--on-surface-variant);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.17;
  transition: transform 0.1s, color 0.2s;
}

.tabs__radio-label::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid var(--inactive);
  border-radius: 10px;
  z-index: -1;
  transition: background-color 0.2s, border 0.2s, transform 0.2s;
}

.tabs__radio-input:checked + .tabs__radio-label {
  color: var(--on-surface);
}

.tabs__radio-input:checked + .tabs__radio-label::before {
  transform: translate(-50%, -50%) scale(1.15);
  border: none;
  background-color: var(--active);
  color: var(--on-surface);
}

.tabs__radio-label:active {
  transform: scale(0.95);
}

.tabs__depart-time {
  font-size: 18px;
}
