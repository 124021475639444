.flight {
  position: relative;
  padding: 36px 20px;
  display: grid;
  grid-template-columns: 110px 1fr max-content;
  grid-template-areas: 'carrier info luggage';
  gap: 20px;
  border-top: 1px dashed var(--primary);
  border-top-left-radius: 15px;
}

.flight:first-of-type {
  border-top: 1px solid var(--surface);
}

@media (max-width: 768px) {
  .flight {
    border-top-right-radius: 15px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'carrier luggage'
      'info info';
  }
}

.flight__special {
  position: absolute;
  top: -1px;
  left: 0;
  padding: 6px 24px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: var(--special);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  color: var(--on-special);
}

.flight__carrier {
  grid-area: carrier;
  /* padding-top: 18px; */
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 8px;
}

@media (max-width: 768px) {
  .flight__carrier {
    padding: 0;
  }
}

.flight__carrier-logo {
  width: 100%;
  height: 40px;
  object-fit: contain;
}

.flight__carrier-title {
  margin: 0;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.17;
  color: var(--on-surface-variant);
}

.flight__info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.flight__way {
  display: grid;
  grid-template-columns: minmax(110px, max-content) 1fr minmax(
      110px,
      max-content
    );
  grid-template-areas: 'stationA track stationB';
  gap: 28px;
}

@media (max-width: 768px) {
  .flight__way {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'stationA stationB'
      'track track';
    gap: 10px;
  }
}

.flight__station {
  display: flex;
  flex-direction: column;
}

.flight__station_type_A {
  grid-area: stationA;
}

.flight__station_type_B {
  grid-area: stationB;
}

@media (max-width: 768px) {
  .flight__station_type_B {
    justify-self: end;
  }
}

.flight__time {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.17;
  margin-bottom: 8px;
}

.flight__city,
.flight__date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: var(--on-surface-variant);
}

.flight__city {
  font-weight: 500;
}

.flight__track {
  grid-area: track;
  position: relative;
  border-bottom: 1px solid var(--border-variant);
  align-self: flex-start;
  padding-bottom: 8px;
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
}

.flight__track::before,
.flight__track::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--border-variant);
}

.flight__track::before {
  left: 0;
  transform: translate(-50%, calc(50% + 0.5px));
}

.flight__track::after {
  right: 0;
  transform: translate(50%, calc(50% + 0.5px));
}

@media (max-width: 768px) {
  .flight__track {
    width: 80%;
    justify-self: center;
  }
}

.flight__code {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: var(--border-variant);
}

.flight__code:first-of-type {
  transform: translateX(-50%);
}

.flight__code:last-of-type {
  transform: translateX(50%);
}

.flight__duration {
  width: calc(100% + 40px);
  text-align: center;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 100%);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: var(--border-variant);
}

.flight__luggage {
  grid-area: luggage;
  display: flex;
  gap: 10px;
  align-items: baseline;
  justify-content: center;
}

.flight__luggage-icon {
  width: 20px;
  object-fit: contain;
}
